import React from "react";

const Pied = () => {
  return (
    <footer>
      <div className="footer__container">
        <div className="footer__contacts">
          <p>info-fzta@fizitech.org</p>
          <p>0822077545</p>
        </div>
      </div>
    </footer>
  );
};

export default Pied;
